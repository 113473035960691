import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

export const constantRoutes = [
  {
    path: "/admin",
    component: () => import("@/views/AdminConsole"),
    hidden: true,
  },
  {
    path: "/",
    component: () => import("@/views/ThatDay"),
    name: "ThatDay",
    meta: { title: "那年今日" },
    hidden: true,
  },
];

const createRouter = () =>
  new VueRouter({
    routes: constantRoutes,
  });

const router = createRouter();

export default router;